import store from '../store'
import HttpConnector from './http-connector'

const httpConnector = new HttpConnector()
const apiEndpoint = process.env.VUE_APP_API_BASE_URL
const apiCode = process.env.VUE_APP_API_CODE
const AppAddOnUtility = {
  initialize: () => {
    console.log(store.state.appAddOns)
    AppAddOnUtility.loadAppAddons()
  },
  loadAppAddons: async () => {
    let userAddons = []
    const { data: allAddons } = await httpConnector.makeRequest(
      'get',
      `${apiEndpoint}appaddontypes/?code=${apiCode}`,
    )
    try {
      const { data } = await httpConnector.makeRequest(
        'get',
        `${apiEndpoint}getuserappaddon/?code=${apiCode}`,
      )
      userAddons = data
    } catch (error) {
      console.log('error :>> ', error)
    }
    const preparedAddOn = AppAddOnUtility._prepareForStore(
      allAddons,
      userAddons,
    )
    store.commit('UPDATE_APP_ADD_ON', preparedAddOn)
    // console.log(mapState(['appAddOns']).appAddOns.get())
  },
  toggleAddOn: (addOnId, value) => {
    const storeAddOns = store.state.appAddOns
    const storeAddOnIndex = storeAddOns.findIndex(
      addOn => addOn.AppAddOnID === addOnId,
    )
    if (storeAddOnIndex > -1) {
      storeAddOns[storeAddOnIndex].isEnabled = value
    }
    store.commit('UPDATE_APP_ADD_ON', JSON.parse(JSON.stringify(storeAddOns)))
    const payload = AppAddOnUtility._preparePayloadFromStore(storeAddOns)
    httpConnector.makeRequest(
      'post',
      `${apiEndpoint}userappaddon/?code=${apiCode}`,
      {
        UserAppAddOns: payload,
      },
    )
  },
  _preparePayloadFromStore: storeAddOns => {
    return storeAddOns
      .filter(addOn => addOn.isEnabled)
      .map(addOn => ({
        AppAddOnID: addOn.AppAddOnID,
      }))
  },
  _prepareForStore: (allAddons, userAddons) => {
    const idMappedUserAddons = {}
    if (userAddons && userAddons.length) {
      userAddons.forEach(userAddOn => {
        idMappedUserAddons[userAddOn.AppAddOnID] = userAddOn
      })
    }

    return allAddons.map(addOn => ({
      ...addOn,
      isEnabled: !!idMappedUserAddons[addOn.AppAddOnID],
    }))
  },
}

export default AppAddOnUtility
