
<template>
  <div class="tabWrapper">
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset-md="9"
          offset-lg="9"
        />
        <v-col
          cols="12"
          md="12"
        >
          <v-row>
            <v-col
              v-for="appAddOn in appAddOns"
              :key="appAddOn.AppAddOnID"
              cols="12"
              md="3"
            >
              <div class="addOnWrapper">
                <div class="toggleWrapper">
                  <input
                    v-model="appAddOn.isEnabled"
                    type="checkbox"
                    class="toggle"
                    :onChange="toggleAddOn"
                    @click="toggleAddOn(appAddOn)"
                  >
                </div>
                <div class="addOnLabelWrapper">
                  <v-icon>
                    mdi-cog-outline
                  </v-icon>
                  <p>{{ appAddOn.AppAddOn }}</p>
                </div>
                <div class="addOnStatusOuterWrapper">
                  <div
                    v-if="appAddOn.isEnabled"
                    class="addOnStatusInnerWrapper"
                  >
                    Enabled
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {
    mapState,
  } from 'vuex'
  import AppAddOnUtility from '../../../utils/app-addon-utility'

  export default {
    components: {

    },
    data: () => ({
      selectedTabIndex: 0,
      isAccordianVisible: false,
      faqDataSet: null,
      panel: [],
      isOverlayVisible: false,
      snackbar: false,
      notification: 'Unknown',
    }),

    computed: {
      ...mapState(['appAddOns']),
    },

    created () {
      this.selectedTabIndex = 0
    },

    methods: {

      showLoader () {
        this.isOverlayVisible = true
      },

      hideLoader () {
        this.isOverlayVisible = false
      },

      onFeedbackSuccess () {
        this.isOverlayVisible = false
        this.notification = 'Feedback submitted successfully'
        this.snackbar = true
      },
      toggleAddOn (addOn) {
        AppAddOnUtility.toggleAddOn(addOn.AppAddOnID, !addOn.isEnabled)
      },
    },
  }

</script>
<style lang="scss" scoped>
  .addOnWrapper {
    width: 100%;
    height: 130px;
    border: 1px solid rgb(221, 169, 0)!important;
    background-color: white!important;
    -webkit-box-shadow: 0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2);
    box-shadow: 0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: rgb(221, 169, 0);
    * {
      color: rgb(221, 169, 0);
    }
    flex-direction: column;
    p {
      font-weight: 600;
    }
    .addOnLabelWrapper {
      text-align: center;
    }
    .addOnStatusOuterWrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      left: 0;
      .addOnStatusInnerWrapper {
        width: 100%;
        color: white;
        height: 100%;
        background: green;
        text-align: center;
        padding-top: 6px;
        font-weight: 600;
        font-size: 0.8rem;
      }
    }
  }
  .toggleWrapper {
  display: flex;
  position: absolute;
  top: 8px;
  right: 6px;
  align-items: center;
  span {
    font-size: 1.1rem;
    margin-left: 1rem;
  }
input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
input[type=checkbox]:focus {
  outline: 0;
}

.toggle {
  height: 16px;
  width: 42px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  margin: 0;
  border: 2px solid grey;
  background: grey;
  transition: all 0.2s ease;
}
.toggle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
  transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}
.toggle:checked {
  border-color: goldenrod;
  background: goldenrod;
}
.toggle:checked:after {
  transform: translatex(20px);
}
  }
</style>
